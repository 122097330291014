import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import { IoSearchSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { mainBlue, mainRed, transHover, screen } from "../variables";
import { toSlug } from "../../helpers";
import Spinner from "../../images/svg/spinner.svg";
import { ScrollLocky } from "react-scroll-locky";

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);

  form {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  input {
    border: 0;
    border-radius: 0;
    outline: none;
    font-size: 16px;
    padding: 5px 0;
    margin: 0 9px 0 0;
    background: none;
    border-bottom: 2px ${mainBlue} solid;
    width: 230px;
    box-shadow: none;
    @media ${screen.xsmall} {
      width: 500px;
      padding: 10px 0;
    }
    @media ${screen.small} {
      font-size: 2rem;
    }

    &::placeholder {
      overflow: visible;
      color: #202121;
      line-height: 20px;
      font-weight: 300;
      opacity: 0.5;
    }
  }

  button {
    all: unset;
    cursor: pointer;
    margin: 0 0 -7px -3px;
    @media ${screen.xsmall} {
      margin: 0 0 -25px -3px;
    }

    svg {
      color: ${mainBlue};
      font-size: 1.65rem;
      @media ${screen.xsmall} {
        font-size: 2.18rem;
      }
    }
  }

  #loading-add {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      position: absolute;
      background: linear-gradient(
        to left bottom,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.35)
      );
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      svg {
        width: 190px;
        height: 190px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      background: rgba(255, 255, 255, 0.19);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      backdrop-filter: blur(10px);
    }
  }

  &::after {
    content: "";
    position: absolute;
    background: rgba(255, 255, 255, 0.19);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(10px);
  }

  .x-btn {
    all: unset;
    line-height: 30px;
    position: absolute;
    cursor: pointer;
    z-index: 3;
    display: flex;
    align-items: center;
    right: 25px;
    top: 20px;
    @media ${screen.xsmall} {
      right: 27px;
      top: 23px;
    }

    .icon {
      display: none;
      @media ${screen.xsmall} {
        display: block;
      }

      svg {
        color: ${mainBlue};
        transition: ${transHover};
        @media ${screen.xsmall} {
          font-size: 1.95rem;
        }
      }
    }

    .label {
      display: none;
      color: ${mainBlue};
      @media ${screen.xsmall} {
        display: block;
        font-size: 1.08rem;
        transition: ${transHover};
        margin: -6px 0 0 0;
      }

      &--close {
        display: block;
        font-size: 0.9rem;
        @media ${screen.xsmall} {
          display: none;
        }
      }
    }

    @media ${screen.withCursor} {
      &:hover svg {
        color: ${mainRed};
      }
      &:hover .label {
        color: ${mainRed};
      }
    }
  }
`;

const SearchForm = ({ className, isSearchOverlay, setIsSearchOverlay }) => {
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const inputEl = useRef(null);

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // → only start search if user has put value in the field
    if (keyword.length) {
      setIsLoading(true);
      // timer to imitate a loading screen
      setTimeout(() => {
        setKeyword("");

        // →navigate to the search page with search url path
        navigate(`/search?${toSlug(keyword)}`);
        setIsSearchOverlay(false);
        setIsLoading(false);
      }, 400);
    }
  };

  useEffect(() => {
    const detectKey = (e) => {
      if (e.keyCode === 27) {
        setIsSearchOverlay(false);
        setKeyword("");
      }
    };
    window.addEventListener("keydown", detectKey);

    if (isSearchOverlay) {
      inputEl.current.focus();
    }

    return () => {
      window.removeEventListener("keydown", detectKey);
    };
  }, [isSearchOverlay]);

  return (
    <AnimatePresence>
      {isSearchOverlay && (
        <Wrapper
          exit={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 5 }}
          className={className}
          onSubmit={handleSubmit}
        >
          <ScrollLocky>
            <form>
              <input
                type="text"
                placeholder="Search for products"
                ref={inputEl}
                value={keyword}
                onChange={handleChange}
              />
              <button>
                <IoSearchSharp />
              </button>
            </form>

            {isLoading && (
              <div id="loading-add">
                <span className="icon">
                  <Spinner />
                </span>
              </div>
            )}

            <button
              className="x-btn"
              onClick={() => {
                setIsSearchOverlay(false);
                setKeyword("");
              }}
            >
              <span className="icon">
                <MdClose />
              </span>
              <span className="label">ESC</span>
              <span className="label label--close">CLOSE</span>
            </button>
          </ScrollLocky>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default SearchForm;
