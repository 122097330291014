import React, { useState } from "react";
import styled from "styled-components";
import {
  mainRed,
  mainWhite,
  mainYellow,
  transHover,
  screen,
} from "../variables";
import Obfuscate from "react-obfuscate";
import Shape from "../../images/svg/cta-shape.svg";

const Wrapper = styled.button`
  display: none;

  @media ${screen.xsmall} {
    display: block;
    all: unset;
    background: ${mainRed};
    color: ${mainWhite};
    position: fixed;
    left: 0;
    z-index: 10;
    bottom: 0;
    padding: 32px 0 32px 40px;
    transform: ${(props) =>
      props.isOpen ? "translateX(0)" : "translateX(-100%);"};
    transition: ${transHover};
  }

  .txt {
    .lg {
      font-size: 1.5rem;
      max-width: 230px;
      line-height: 1.35;

      &--top {
        margin: 0 0 26px 0;
      }
      &--bot {
        margin: 0 0 7px 0;
      }
    }

    .sm {
      a {
        color: ${mainWhite};
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainYellow};
          }
        }
      }

      &--underlined {
        text-decoration: underline;
      }
    }
  }

  .shape-wrapper {
    position: absolute;
    right: -60px;
    top: 0;

    svg {
      width: 60px;
    }

    .label {
      position: absolute;
      top: 0;
      left: -20px;
      white-space: pre;
      transform: rotate(-90deg) translateY(-50%);
      top: 50%;
      opacity: ${(props) => (props.isOpen ? 0 : 1)};
      transition: ${transHover};
    }
  }
`;

const StickyCtaBtn = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
    >
      <div className="txt">
        <p className="lg lg--top">Can’t find what you’re looking for?</p>
        <p className="lg lg--bot">Contact us</p>
        <p className="sm sm--underlined">
          <Obfuscate className="link" email={"sales@f-pak.com.au"} />
        </p>
        <p className="sm">
          <a href="tel:0732172121">(07) 3217 2121</a>
        </p>
      </div>

      <div className="shape-wrapper">
        <Shape />
        <span className="label">GET IN TOUCH</span>
      </div>
    </Wrapper>
  );
};

export default StickyCtaBtn;
