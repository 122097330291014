export const organization = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "F-Pak Wholesalers",
  legalName: "F-Pak Wholesalers Pty Ltd",
  url: "https://www.f-pak.com.au",
  logo: "https://www.f-pak.com.au/f-pak-wholesalers-logo.png",
  foundingDate: "2020",
  founders: [
    {
      "@type": "Person",
      name: "Fotis Hatzifotis",
    },
  ],
  address: {
    "@type": "PostalAddress",
    streetAddress: "36 Evesham St, Moorooka",
    addressLocality: "36 Evesham St, Moorooka",
    addressRegion: "QLD",
    postalCode: "4105",
    addressCountry: "AU",
  },
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "support",
    telephone: "[0732172121]",
    email: "sales@f-pak.com.au",
  },
  sameAs: ["https://www.facebook.com/fpakwholesalers"],
};

export const getProductSchema = ({ name, image, category, description }) => {
  return {
    "@context": "https://www.schema.org",
    "@type": "product",
    brand: "F-Pak Wholesalers",
    logo: "https://www.f-pak.com.au/f-pak-wholesalers-logo.png",
    name: name,
    category: category,
    image: image,
    description: description,
  };
};
