import React, { useContext, useState } from "react";
import styled from "styled-components";
import { mainBlue, mainRed, mainWhite, screen, transHover } from "../variables";
import { Link } from "gatsby";
import { StoreContext } from "../../context";
import Logo from "../../images/svg/f-pak-logo.svg";
import { MdShortText, MdClose } from "react-icons/md";
import { IoSearchSharp } from "react-icons/io5";
import OverlayDesktop from "./overlay-desktop";
import OverlayMobile from "./overlay-mobile";
import SearchForm from "./search-form";

const Wrapper = styled.nav`
  background: ${mainWhite};
  padding: 28px 0 13px 0;
  position: relative;
  z-index: 50;

  #menu-desktop {
    display: none;
    @media ${screen.small} {
      display: block;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        display: block;
        width: 140px;
      }

      .nav-list {
        display: none;
        display: flex;
        align-items: flex-end;
        padding: 0 0 27px 0;

        &__each {
          margin: 0 52px 0 0;
          a {
            color: ${mainBlue};
            font-size: 1.04rem;
            white-space: pre;
          }

          &--products {
            position: relative;
            margin: 0 54px 0 0;
            .toggle-icon {
              color: ${mainBlue};
              position: absolute;
              right: -13px;
              top: 0;
            }
          }
        }
      }

      .search-quote {
        display: flex;
        align-items: flex-start;

        .icon-search {
          font-size: 1.5rem;
          color: ${mainBlue};
          margin: 6px 16px 0 0;
          cursor: pointer;
        }

        .btn {
          &--quote {
            border: 2px ${mainBlue} solid;
            color: ${mainBlue};
            padding: 8px 25px 6px 25px;
            position: relative;
            @media ${screen.withCursor} {
              &:hover {
                background: ${mainBlue};
                color: ${mainWhite};
              }
            }

            .num-count {
              background: ${mainRed};
              width: 20px;
              height: 20px;
              display: block;
              position: absolute;
              color: ${mainWhite};
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              right: -10px;
              top: -10px;
              padding: 14px;

              span {
                font-size: 0.85rem;
                letter-spacing: 0.4px;
                display: block;
                padding: 2px 0 0 0;
              }
            }
          }
        }
      }

      .active {
        text-decoration: underline;
      }

      .active-quote {
        background: ${mainBlue};
        color: ${mainWhite} !important;
      }
    }
  }

  #menu-mobile {
    height: 75px;
    background: ${(props) => (props.isMobileMenu ? mainBlue : mainWhite)};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: ${(props) =>
      props.isMobileMenu ? "none" : "0 3px 10px -2px rgba(0, 0, 0, 0.085)"};
    transition: ${transHover};
    @media ${screen.small} {
      display: none;
    }

    .logo {
      width: 112px;
      display: block;
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        .bae93115-423d-4644-a165-b41389587516 {
          fill: ${(props) => (props.isMobileMenu ? mainWhite : mainBlue)};
          transition: ${transHover};
        }
      }
    }

    .icon-menu {
      position: absolute;
      right: 20px;
      transform: translateY(-50%);
      color: ${(props) => (props.isMobileMenu ? mainWhite : mainBlue)};
      font-size: ${(props) => (props.isMobileMenu ? "2.2rem" : "2.7rem")};
      top: ${(props) =>
        props.isMobileMenu ? "calc(50% + 2px)" : "calc(50% + 6px)"};
    }

    .icon-search {
      position: absolute;
      right: 76px;
      transform: translateY(-50%);
      color: ${(props) => (props.isMobileMenu ? mainWhite : mainBlue)};
      transition: ${transHover};
      font-size: 1.3rem;
      top: calc(50% + 2px);
    }
  }
`;

const TransparentOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;

  &::before {
    content: "";
    position: absolute;
    background: rgba(32, 33, 33, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    backdrop-filter: blur(10px);
  }

  &::after {
    content: "";
    position: absolute;
    background: rgba(32, 33, 33, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(10px);
  }
`;

const Nav = () => {
  const { orders, setIsFormPackaging } = useContext(StoreContext);

  const [isDropdownDesktop, setIsDropdownDesktop] = useState(false);
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isSearchOverlay, setIsSearchOverlay] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isProductDropdown, setIsProductDropdown] = useState(false);

  const closeDropdown = () => {
    setIsDropdownDesktop(false);
  };

  const isNotEmpty = orders.length !== 0;

  return (
    <>
      <Wrapper id="top" isMobileMenu={isMobileMenu}>
        <div id="menu-desktop">
          <div className="inner-wrapper flex">
            <ul className="nav-list">
              <li className="nav-list__each">
                <Link className="logo" to="/">
                  <Logo />
                </Link>
              </li>
              <li className="nav-list__each">
                <Link to="/" activeClassName="active">
                  Home
                </Link>
              </li>
              <li className="nav-list__each nav-list__each--products">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDropdownDesktop(!isDropdownDesktop);
                  }}
                  to="/products/"
                  activeClassName="active"
                >
                  Products
                </Link>{" "}
                {isDropdownDesktop ? (
                  <span className="toggle-icon">&minus;</span>
                ) : (
                  <span className="toggle-icon">+</span>
                )}
                <OverlayDesktop
                  isDropdownDesktop={isDropdownDesktop}
                  closeDropdown={closeDropdown}
                  setIsFormPackaging={setIsFormPackaging}
                />
              </li>
              <li className="nav-list__each">
                <Link to="/about/" activeClassName="active">
                  Why choose us
                </Link>
              </li>
              <li className="nav-list__each">
                <Link to="/faqs/" activeClassName="active">
                  FAQs
                </Link>
              </li>
            </ul>

            <div className="search-quote">
              <span
                className="icon-search"
                role="button"
                onClick={() => {
                  setIsSearchOverlay(true);
                }}
              >
                <IoSearchSharp />
              </span>

              <Link
                to="/quote/"
                className="btn btn--quote"
                activeClassName="active-quote"
              >
                Quote
                {isNotEmpty && (
                  <span className="num-count" role="presentation">
                    <span>{orders.length}</span>
                  </span>
                )}
              </Link>
            </div>
          </div>
        </div>

        <div id="menu-mobile">
          <Link className="logo" to="/">
            <Logo />
          </Link>

          <span
            className="icon-search"
            role="button"
            onClick={() => {
              setIsSearchOverlay(true);
            }}
          >
            <IoSearchSharp />
          </span>
          <span
            className="icon-menu"
            role="button"
            onClick={() => {
              setIsMobileMenu(!isMobileMenu);
              setIsContact(false);
            }}
          >
            {isMobileMenu ? <MdClose /> : <MdShortText />}
          </span>
        </div>
      </Wrapper>
      <OverlayMobile
        isMobileMenu={isMobileMenu}
        setIsMobileMenu={setIsMobileMenu}
        orders={orders}
        isNotEmpty={isNotEmpty}
        isContact={isContact}
        setIsContact={setIsContact}
        isProductDropdown={isProductDropdown}
        setIsProductDropdown={setIsProductDropdown}
      />
      {isDropdownDesktop && (
        <TransparentOverlay
          onClick={() => {
            setIsDropdownDesktop(false);
          }}
        />
      )}

      <SearchForm
        isSearchOverlay={isSearchOverlay}
        setIsSearchOverlay={setIsSearchOverlay}
      />
    </>
  );
};

export default Nav;
