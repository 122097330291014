import React from "react";
import styled from "styled-components";
import { toSlug } from "../../helpers";
import { motion, AnimatePresence } from "framer-motion";
import { graphql, Link, useStaticQuery } from "gatsby";
import { mainWhite, mainBlue, mainBlack } from "../variables";
import YellowShape from "../../images/svg/yellow-shape.svg";

const Wrapper = styled(motion.div)`
  position: absolute;
  width: 950px;
  left: -285px;
  z-index: 10;
  top: 59px;

  .top {
    display: flex;

    &::after {
      content: "";
      width: 24px;
      height: 24px;
      background: #e8e8ea;
      display: block;
      position: absolute;
      top: -12px;
      left: 310px;
      transform: rotate(45deg);
    }

    .col {
      .subheading {
        color: ${mainBlack};
        font-size: 0.72rem;
        font-weight: 500;
        margin: 0 0 14px 0;
        display: block;
      }

      &--one {
        width: 40%;
        background: #e8e8ea;
        padding: 24px;
        border-right: 1px ${mainWhite} solid;
      }

      &--two {
        width: 35%;
        background: #e8e8ea;
        padding: 24px;
        border-left: 1px ${mainWhite} solid;
      }

      &--three {
        width: 25%;
        background: ${mainWhite};

        .row {
          height: 33.33%;
          display: flex;
          align-items: center;
          padding: 0 0 0 25px;

          &--mid {
            border-top: 2px #e8e8ea solid;
          }

          &--bot {
            border-top: 2px #e8e8ea solid;
          }

          a {
            font-weight: 500;
            font-size: 1rem;
          }
        }
      }

      .link-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &__each {
          width: 50%;
          margin: 0 0 11px 0;

          a {
            font-size: 0.95rem;

            &:hover {
              color: ${mainBlack};
            }
          }
        }
      }
    }
  }

  .bot {
    display: flex;

    .col {
      text-align: center;

      &--left {
        background: ${mainBlue};
        width: 75%;
        padding: 21px 0 20px 0;
        position: relative;
        z-index: 2;

        .link {
          color: ${mainWhite} !important;
          position: relative;
          z-index: 2;
        }
      }

      &--right {
        position: relative;
        width: 25%;
        padding: 21px 0 20px 25px;
        text-align: left;

        .yellow-bg {
          position: absolute;

          right: 0;
          top: 0;

          display: block;

          svg {
            width: 293px;
          }
        }

        .link {
          color: ${mainWhite};
          position: relative;
          z-index: 2;
          font-weight: 500;
        }
      }
    }
  }
`;

const DropdownDesktop = ({
  isDropdownDesktop,
  closeDropdown,
  setIsFormPackaging,
}) => {
  const data = useStaticQuery(dataQuery);

  const { kinds, industries } = data;

  return (
    <AnimatePresence>
      {isDropdownDesktop && (
        <Wrapper
          exit={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -10 }}
        >
          <div className="top">
            <div className="col col--one">
              <span className="subheading">VIEW BY KIND</span>

              <ul className="link-list">
                {kinds.edges.map((item) => (
                  <li className="link-list__each" key={item.node.id}>
                    <Link
                      to={`/products?f=kind&q=${toSlug(
                        item.node.data.title.text
                      )}`}
                      onClick={closeDropdown}
                    >
                      {item.node.data.title.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col col--two">
              <span className="subheading">VIEW BY INDUSTRY</span>

              <ul className="link-list">
                {industries.edges.map((item) => (
                  <li className="link-list__each" key={item.node.id}>
                    <Link
                      to={`/products?f=industry&q=${toSlug(
                        item.node.data.title.text
                      )}`}
                      onClick={closeDropdown}
                    >
                      {item.node.data.title.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col col--three">
              <div className="row">
                <Link to="/products/?q=new-product" onClick={closeDropdown}>
                  New Products
                </Link>
              </div>
              <div className="row row--mid">
                <Link to="/products/?q=best-seller" onClick={closeDropdown}>
                  Best Sellers
                </Link>
              </div>
              <div className="row row--bot">
                <Link to="/products?f=enviropak" onClick={closeDropdown}>
                  EnviroPak Range
                </Link>
              </div>
            </div>
          </div>

          <div className="bot">
            <div className="col col--left">
              <Link className="link" to="/products/" onClick={closeDropdown}>
                VIEW PRODUCTS
              </Link>
            </div>
            <div className="col col--right">
              <Link
                className="link"
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  setIsFormPackaging(true);
                  closeDropdown();
                }}
              >
                Custom Packaging
              </Link>

              <span className="yellow-bg">
                <YellowShape />
              </span>
            </div>
          </div>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default DropdownDesktop;

const dataQuery = graphql`
  {
    kinds: allPrismicKind {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
    industries: allPrismicIndustry {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
