import React, { useState } from "react";
import styled from "styled-components";
import {
  dirtyWhite,
  mainBlue,
  mainWhite,
  mainRed,
  mainYellow,
  screen,
} from "../variables";
import { graphql, Link, useStaticQuery } from "gatsby";
import { motion } from "framer-motion";
import Logo from "../../images/svg/f-pak-logo.svg";
import mailchimpHandler from "../../helpers/mailchimp-handler";
import { toSlug } from "../../helpers";

const Wrapper = styled.footer`
  overflow: hidden;

  .flex {
    display: flex;
    flex-direction: column-reverse;
    @media ${screen.small} {
      flex-direction: row;
    }

    .col {
      position: relative;

      &--blue {
        background: ${mainBlue};
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 50px 0;
        @media ${screen.small} {
          width: 67%;
          padding: 100px 50px 100px 0;
          justify-content: flex-start;
        }

        .inner-col {
          position: relative;
          z-index: 2;
          width: 33.33%;
          @media ${screen.xsmall} {
            width: 20.66%;
          }
          @media ${screen.small} {
            width: 21%;
          }

          .logo {
            width: 190px;
            display: block;

            svg {
              .bae93115-423d-4644-a165-b41389587516 {
                fill: ${mainWhite};
              }
            }
          }

          .p {
            display: block;
            color: ${mainWhite};
            font-weight: 300;
            font-size: 0.77rem;
            line-height: 1.8;
            @media ${screen.xsmall} {
              font-size: 0.9rem;
              line-height: 1.6;
            }

            strong {
              display: block;
              font-weight: 700;
              margin: 0 0 10px 0;
            }
          }

          a {
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainYellow};
              }
            }
          }

          &--1st {
            display: none;
            @media ${screen.xsmall} {
              width: 38%;
              color: ${mainWhite};
              display: block;
            }
            @media ${screen.small} {
              width: 37%;
            }

            .subheading {
              display: block;
              margin: 25px 0 5px 53px;
              font-size: 0.85rem;
            }

            .link {
              color: ${mainWhite};
              max-width: 200px;
              font-weight: 200;
              display: inline-block;
              line-height: 1.35;
              font-size: 0.85rem;
              margin: 0 0 0 53px;
            }
          }
        }

        &::after {
          content: "";
          background: ${mainBlue};
          position: absolute;
          top: 0;
          left: 50%;
          height: 100%;
          width: 200%;
          transform: translateX(-50%);
          @media ${screen.small} {
            left: -40%;
            transform: translateX(0);
            width: 50%;
          }
        }
      }

      &--yellow {
        background: ${mainYellow};
        padding: 30px 0;
        @media ${screen.xsmall} {
          padding: 50px 0;
        }
        @media ${screen.small} {
          width: 33%;
          padding: 45px 0 45px 60px;
        }

        .inner-col {
          position: relative;
          z-index: 2;

          .heading {
            color: ${mainBlue};
            font-size: 1.3rem;
            font-weight: 300;
            margin: 0 0 20px 0;
            max-width: 250px;
            line-height: 1.4;
            @media ${screen.xsmall} {
              font-size: 1.7rem;
              max-width: none;
              margin: 0 0 30px 0;
            }
          }

          .form {
            .row {
              margin: 20px 0 0 0;
              label {
                display: block;
                color: ${mainWhite};
                font-size: 0.8rem;
                margin: 0 0 5px 0;
                @media ${screen.xsmall} {
                  font-size: 0.9rem;
                }
              }

              input {
                display: block;
                border: 0;
                border-radius: 0;
                width: 100%;
                outline: none;
                font-size: 16px;
                padding: 11px 10px;
              }

              &--top {
                display: flex;
                margin: 0 -7px;

                .col {
                  width: 50%;
                  margin: 0 7px;
                }
              }
            }

            .btn {
              border: 2px ${mainBlue} solid;
              color: ${mainBlue};
              margin: 20px 0 0 0;
              display: block;
              text-align: center;
              width: calc(100% - 50px);
              @media ${screen.xsmall} {
                width: auto;
              }

              @media ${screen.withCursor} {
                &:hover {
                  background: ${mainBlue};
                  color: ${mainWhite};
                }
              }
            }
          }
        }

        &::before {
          content: "";
          background: ${(props) =>
            props.rotatedSquareColour ? props.rotatedSquareColour : dirtyWhite};
          width: 76px;
          height: 76px;
          top: -38px;
          right: -63px;
          position: absolute;
          transform: rotate(45deg);
          z-index: 2;
          @media ${screen.xsmall} {
            right: -86px;
          }
          @media ${screen.small} {
            left: -38px;
            right: auto;
          }
        }

        &::after {
          content: "";
          background: ${mainYellow};
          position: absolute;
          top: 0;
          left: 50%;
          height: 100%;
          width: 200%;
          transform: translateX(-50%);
          @media ${screen.small} {
            left: -40%;
            transform: translateX(0);
            width: 100%;
            right: -100%;
            left: auto;
          }
        }
      }
    }
  }

  .bot {
    background: ${mainWhite};
    padding: 10px 0;

    p {
      font-size: 0.75rem;
      color: ${mainBlue};
      font-weight: 300;
      line-height: 1.85;

      .pipe {
        display: none;
        @media ${screen.xsmall} {
          display: inline;
          margin: 0 3px;
        }
      }

      a {
        color: ${mainRed};
        font-weight: 400;
        text-decoration: underline;
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainYellow};
          }
        }
      }

      .link {
        display: block;
        @media ${screen.xsmall} {
          display: inline;
        }
      }
    }
  }
`;

const Footer = ({ rotatedSquareColour }) => {
  const [userInput, setUserInput] = useState({});
  const [isSending, setIsSending] = useState(false);
  const data = useStaticQuery(dataQuery);
  const { kinds, industries } = data;

  const handleChange = (e) => {
    userInput[e.target.name] = e.target.value;

    setUserInput({ ...userInput });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    mailchimpHandler({
      first_name: userInput.first_name,
      last_name: userInput.last_name,
      email: userInput.email,
    });
  };

  return (
    <Wrapper rotatedSquareColour={rotatedSquareColour}>
      <div className="inner-wrapper flex">
        <div className="col col--blue">
          <div
            className="inner-col inner-col--1st"
            data-sal="fade"
            data-sal-duration="700"
          >
            <Link className="logo" to="/">
              <Logo />
            </Link>

            <span className="subheading">Visit us at</span>
            <a
              className="link"
              href="https://www.google.com/maps/place/F-Pak+Wholesalers+Pty+Ltd/@-27.5327471,153.0123503,17z/data=!3m1!4b1!4m5!3m4!1s0x6b915009e324f599:0x5ed14bea5ec0d650!8m2!3d-27.5327471!4d153.014539"
              target="_blank"
            >
              36 Evesham St, Moorooka Queensland AU 4105
            </a>
          </div>
          <div className="inner-col" data-sal="fade" data-sal-duration="700">
            <p className="p">
              <strong>Products</strong>
            </p>

            {kinds.edges.slice(0, 7).map((item) => (
              <Link
                key={item.node.id}
                className="p"
                to={`/products?f=kind&q=${toSlug(item.node.data.title.text)}`}
              >
                {item.node.data.title.text}
              </Link>
            ))}
          </div>
          <div className="inner-col" data-sal="fade" data-sal-duration="700">
            <p className="p">
              <strong>Industry</strong>
            </p>
            {industries.edges.slice(0, 7).map((item) => (
              <Link
                key={item.node.id}
                className="p"
                to={`/products?f=industry&q=${toSlug(
                  item.node.data.title.text
                )}`}
              >
                {item.node.data.title.text}
              </Link>
            ))}
          </div>
          <div className="inner-col" data-sal="fade" data-sal-duration="700">
            <p className="p">
              <strong>Help & Support</strong>
            </p>
            <Link className="p" to="/faqs/">
              FAQs
            </Link>
            <Link className="p" to="/faqs/">
              Shipping & Delivery
            </Link>
            <Link className="p" to="/faqs/">
              Returns
            </Link>
            <Link className="p" to="/privacy-policy/">
              Privacy Policy
            </Link>
            <Link className="p" to="/terms-and-conditions/">
              Terms & Conditions
            </Link>
          </div>
        </div>
        <div className="col col--yellow">
          <div className="inner-col" data-sal="fade" data-sal-duration="700">
            <h6 className="heading">
              Stay in the loop for new products & promotions.
            </h6>
            <form className="form" onSubmit={handleSubmit}>
              <div className="row row--top">
                <div className="col">
                  <label>FIRST NAME</label>
                  <input
                    type="text"
                    name="first_name"
                    value={userInput.first_name || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col">
                  <label>LAST NAME</label>
                  <input
                    type="text"
                    name="last_name"
                    value={userInput.last_name || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <label>WHAT'S YOUR EMAIL ADDRESS?</label>
                <input
                  type="email"
                  name="email"
                  value={userInput.email || ""}
                  onChange={handleChange}
                  required
                />
                <motion.button whileTap={{ scale: 0.96 }} className="btn">
                  {isSending ? "PLEASE WAIT..." : "STAY UPDATED"}
                </motion.button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="bot">
        <div className="inner-wrapper" data-sal="fade" data-sal-duration="700">
          <p>
            &copy; Copyright by F-Pak Wholesalers{" "}
            <span className="pipe">|</span>{" "}
            <span className="link">
              Website by{" "}
              <a href="" target="_blank">
                Handsome Creative.
              </a>
            </span>
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;

const dataQuery = graphql`
  {
    kinds: allPrismicKind {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
    industries: allPrismicIndustry {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
