import React from "react";
import PropTypes from "prop-types";
import "../css/global.css";
import styled from "styled-components";
import useScrollPosition from "@react-hook/window-scroll";
import {
  mainFont,
  mainBlack,
  mainRed,
  mainWhite,
  siteWidth,
  innerWidth,
  screen,
} from "../components/variables";
import Footer from "../components/footer";
import Nav from "../components/nav";
import StickyCtaBtn from "../components/sticky-cta-btn";
import Newsletter from "../components/forms/newsletter";
import CustomPackaging from "../components/forms/custom-packaging";
import { ImArrowUp2 } from "react-icons/im";
import { Link as ScrollLink } from "react-scroll";

const Wrapper = styled.div`
  max-width: ${siteWidth};
  margin: 0 auto;
  font-family: ${mainFont};
  color: ${mainBlack};

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .btn {
    all: unset;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.3;
    padding: 10px 25px 7px 25px;
    transition: all 0.18s ease-out;
    font-weight: 500;
    display: table;
  }

  #back-up {
    background: ${mainRed};
    position: fixed;
    bottom: 11px;
    right: 11px;
    width: 46px;
    height: 46px;
    cursor: pointer;
    z-index: 9;
    @media ${screen.xsmall} {
      bottom: 15px;
      right: 15px;
      width: 50px;
      height: 50px;
    }

    span {
      font-size: 1.26rem;
      transition: 0.26s all ease-in-out;
      position: absolute;
      color: ${mainWhite};
      top: calc(50% + 3px);
      left: 50%;
      transform: translate(-50%, -50%);
      @media ${screen.xsmall} {
        font-size: 1.4rem;
        top: calc(50% + 2px);
      }
    }
  }
`;

const Layout = ({
  children,
  rotatedSquareColour,
  isHomePage,
  isSubmittedPage,
}) => {
  // dont show newsletter popup on home page and submitted page

  const scrollY = useScrollPosition(60);

  const isBackToTop = scrollY > 800;

  const isNewsletter = isHomePage || isSubmittedPage;
  return (
    <Wrapper>
      <Nav />
      <main>{children}</main>
      <Footer rotatedSquareColour={rotatedSquareColour} />
      {!isNewsletter && <Newsletter />}

      <CustomPackaging />
      <StickyCtaBtn />

      <ScrollLink
        style={{
          opacity: isBackToTop ? 1 : 0,
          pointerEvents: isBackToTop ? "all" : "none",
        }}
        id="back-up"
        to="top"
        smooth={true}
      >
        <span>
          <ImArrowUp2 />
        </span>
      </ScrollLink>
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
