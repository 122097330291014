// website width container
export const siteWidth = "1920px";
export const innerWidth = "1340px";

// regex for email validation
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const transHover = `all 0.1s ease-out`;

export const mainBlue = "#000084";
export const mainYellow = "#FECD05";
export const mainRed = "#F13E2F";
export const mainBlack = "#202121";
export const mainWhite = "#FFFFFF";
export const dirtyWhite = "#F1F1F2";

export const mainFont = `'HelveticaNeue', sans-serif`;

// Media queries for devices
export const screen = {
  xsmall: `(min-width: 650px)`, // → tablet vertical
  small: `(min-width: 960px)`, // → tablet horizontal
  navDesktop: `(min-width: 1041px)`,
  medium: `(min-width: 1400px)`, // → small laptops
  large: `(min-width: 1530px)`, // → large laptop and regular size desktop
  minilarge: `(min-width: 1278px) and (max-height: 690px)`, // → laptop with short height
  xlarge: `(min-width: 1800px)`, // → large size desktop
  withCursor: `not all and (pointer: coarse)`, // → device with cursor. not touch screen devices
  dropdownWide: `(min-width: 1630px)`,
};
