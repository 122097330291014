import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { mainBlue, mainRed, mainWhite, screen } from "../variables";
import { MdClose } from "react-icons/md";
import emailjs from "emailjs-com";
import { motion, AnimatePresence } from "framer-motion";
import { ScrollLocky } from "react-scroll-locky";
import { StoreContext } from "../../context";

// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1, pointerEvents: "all" },
  close: { opacity: 0, pointerEvents: "none" },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 80,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)`
  background: ${mainWhite};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 111;

  .inner {
    background: #e8e8ea;
    color: ${mainBlue};
    position: absolute;
    padding: 50px 32px 50px 32px;
    max-width: 1000px;
    width: calc(100% - 32px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media ${screen.xsmall} {
      width: calc(100% - 60px);
      padding: 60px 110px 60px 110px;
    }
    @media ${screen.medium} {
      width: 100%;
      padding: 60px 130px 120px 130px;
    }

    .heading {
      font-size: 1.6rem;
      font-weight: 300;
      @media ${screen.xsmall} {
        font-size: 2rem;
      }
    }

    .form {
      margin: 30px 0 0 0;

      .row {
        display: flex;
        margin: 0 -8px;

        .col {
          width: 50%;
          margin: 0 8px;
        }
      }

      label {
        display: block;
        font-size: 0.75rem;
        font-weight: 500;
        margin: 0 0 7px 0;
        @media ${screen.xsmall} {
          font-size: 0.85rem;
        }
      }

      input {
        display: block;
        width: 100%;
        border: 0;
        border-radius: 0;
        font-size: 16px;
        padding: 10px 7px;
        margin: 0 0 20px 0;
        outline: none;
        @media ${screen.xsmall} {
          margin: 0 0 25px 0;
        }
      }

      textarea {
        display: block;
        width: 100%;
        border: 0;
        border-radius: 0;
        font-size: 16px;
        padding: 10px 7px;
        margin: 0 0 20px 0;
        outline: none;
        resize: none;
        height: 90px;
        @media ${screen.xsmall} {
          height: 120px;
          margin: 0 0 25px 0;
        }
      }

      .btn {
        border: 2px ${mainBlue} solid;
        @media ${screen.withCursor} {
          &:hover {
            background: ${mainBlue};
            color: ${mainWhite};
          }
        }
      }
    }

    .x-btn {
      all: unset;
      line-height: 30px;
      font-size: 1.6rem;
      position: absolute;
      right: 11px;
      top: 11px;
      cursor: pointer;
      @media ${screen.xsmall} {
        font-size: 1.95rem;
        right: 13px;
        top: 13px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainRed};
        }
      }
    }

    .graphic {
      display: none;
      @media ${screen.medium} {
        display: block;
        position: absolute;
        width: 430px;
        left: -115px;
        bottom: -118px;
        pointer-events: none;
      }
    }

    &::after {
      content: "";
      width: 100px;
      height: 100px;
      background: ${mainWhite};
      display: block;
      position: absolute;
      right: -50px;
      bottom: -50px;
      transform: rotate(45deg);
      @media ${screen.xsmall} {
        width: 150px;
        height: 150px;
        right: -75px;
        bottom: -75px;
      }
    }
  }
`;

const Newsletter = () => {
  const [userInput, setUserInput] = useState({});
  const [isSending, setIsSending] = useState(false);
  const { isFormPackaging, setIsFormPackaging } = useContext(StoreContext);

  const handleChange = (e) => {
    userInput[e.target.name] = e.target.value;

    setUserInput({ ...userInput });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .send(
        "service_2mx6w69",
        "template_ia7n7rr",
        userInput,
        "user_GrbU2PqIALC99ZsBnzzrK"
      )
      .then(() => {
        setIsSending(false);
        setUserInput({});
        setIsFormPackaging(false);
        navigate("/submitted/", { state: { form: "packaging" } });
      })
      .catch(() => {
        setIsSending(false);
        alert("Sorry, Something went wrong. Please contact us immediately.");
      });
  };

  useEffect(() => {
    const userClick = (e) => {
      if (e.target.id === "packaging") {
        closePopup();
      }
    };
    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  const closePopup = () => {
    setIsFormPackaging(false);
  };

  return (
    <AnimatePresence>
      {isFormPackaging && (
        <Wrapper
          initial="close"
          animate={isFormPackaging ? "open" : "close"}
          variants={variantsContainer}
          exit={{ opacity: 0 }}
          id="packaging"
        >
          <ScrollLocky isolation={false}>
            <motion.div className="inner" variants={variantsList}>
              <motion.div variants={variantsItem}>
                <h6 className="heading">Enquire about custom packaging.</h6>

                <form className="form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label>NAME</label>
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={userInput.name || ""}
                        required
                      />
                    </div>
                    <div className="col">
                      <label>COMPANY</label>
                      <input
                        type="text"
                        name="company"
                        onChange={handleChange}
                        value={userInput.company || ""}
                      />
                    </div>
                  </div>
                  <label>EMAIL ADDRESS</label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={userInput.email || ""}
                    required
                  />

                  <label>TELL US MORE</label>
                  <textarea
                    type="email"
                    name="message"
                    onChange={handleChange}
                    value={userInput.message || ""}
                    required
                  />

                  <motion.button whileTap={{ scale: 0.96 }} className="btn">
                    {isSending ? "PLEASE WAIT..." : "SUBMIT ENQUIRY"}
                  </motion.button>
                </form>
              </motion.div>

              <button className="x-btn" onClick={closePopup}>
                <MdClose />
              </button>
              <StaticImage
                className="graphic"
                src="../../images/F-Pak-Stay-In-The-Loop.png"
                alt="Box"
              />
            </motion.div>
          </ScrollLocky>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default Newsletter;
