import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { organization, getProductSchema } from "../structured-data";

const defaultImg = "https://www.f-pak.com.au/f-pak-wholesalers-logo.png";

function Seo({
  description,
  lang,
  meta,
  title,
  image,
  isHomePage,
  productInfo,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image || defaultImg,
        },
        {
          property: `og:color`,
          content: `#FECD05`,
        },
        {
          property: `og:image:width`,
          content: "600",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {isHomePage && (
        <script type="application/ld+json">
          {JSON.stringify(organization)}
        </script>
      )}

      {productInfo && (
        <script type="application/ld+json">
          {JSON.stringify(
            getProductSchema({
              name: productInfo.name,
              image: productInfo.image,
              category: productInfo.category,
              description: productInfo.description,
            })
          )}
        </script>
      )}
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
