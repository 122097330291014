import React from "react";
import styled from "styled-components";
import { mainBlue, mainWhite, mainRed, screen, mainYellow } from "../variables";
import { Link, graphql, useStaticQuery } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import { toSlug } from "../../helpers";
import { ScrollLocky } from "react-scroll-locky";
import Obfuscate from "react-obfuscate";

// motion framer animation props value
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
    },
  },
};

const item = {
  hidden: { opacity: 0, x: -40 },
  show: { opacity: 1, x: 0 },
};

const Wrapper = styled(motion.div)`
  background: ${mainBlue};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 100px 25px 0 25px;
  overflow: scroll;
  @media ${screen.xsmall} {
    display: none;
  }

  .subwrapper {
    .btn {
      border: 2px ${mainWhite} solid;
      color: ${mainWhite};
      display: block;
      padding: 8px 25px 4px 25px;
      text-align: center;

      &--quote {
        position: relative;

        .num-count {
          background: ${mainRed};
          width: 20px;
          height: 20px;
          display: block;
          position: absolute;
          color: ${mainWhite};
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          right: -9px;
          top: -12px;
          padding: 14px;

          span {
            font-size: 0.85rem;
            display: block;
            padding: 2px 0 0 0;
          }
        }
      }
    }

    .link-list {
      padding: 0 0 65px 0;

      &__each {
        margin: 28px 0 0 0;
        color: ${mainWhite};
        a {
          color: ${mainWhite};
          font-size: 1.15rem;
        }

        .active {
          text-decoration: underline;
        }

        &--products {
          .dropdown {
            padding: 0 0 0 20px;
            margin: 14px 0 0 0;

            &__each {
              display: flex;
              margin: 0 0 14px 0;

              a {
                font-size: 1rem;
                font-weight: 300;
              }

              &:last-child {
                a {
                  color: ${mainYellow};
                }
              }
            }
          }
        }
      }
    }

    .contact {
      background: ${mainRed};
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      padding: 44px 25px;
      color: ${mainWhite};
      overflow: hidden;
      opacity: ${(props) => (props.isContact ? 1 : 0)};
      pointer-events: ${(props) => (props.isContact ? "all" : "none")};
      z-index: 2;
      transition: opacity 0.19s ease-out;

      h6 {
        font-size: 0.95rem;
        font-weight: 500;
        margin: 0 0 10px 0;
      }

      .link {
        color: ${mainWhite};
        display: inline-block;
        margin: 13px 0 0 0;

        &--email {
          text-decoration: underline;
        }
      }

      &::after {
        content: "";
        display: block;
        background: ${mainBlue};
        width: 100px;
        height: 100px;
        position: absolute;
        bottom: -50px;
        right: -50px;
        transform: rotate(45deg);
      }
    }

    .cta {
      all: unset;
      color: ${mainWhite};
      border-bottom: 1px ${mainWhite} solid;
      position: absolute;
      bottom: 44px;
      text-align: center;
      left: 50%;
      white-space: pre;
      font-weight: 300;
      padding: 0 2px;
      transform: translateX(-50%);
      z-index: 1;
    }
  }
`;

const OverlayMobile = ({
  orders,
  isNotEmpty,
  isMobileMenu,
  setIsMobileMenu,
  isContact,
  setIsContact,
  isProductDropdown,
  setIsProductDropdown,
}) => {
  const data = useStaticQuery(dataQuery);

  const { kinds } = data;

  const closeOverlayMenu = () => {
    setIsMobileMenu(false);
  };

  const isContactOpen = !isProductDropdown && isContact;

  return (
    <AnimatePresence>
      {isMobileMenu && (
        <ScrollLocky isolation={false} enabled={isMobileMenu}>
          <Wrapper
            initial="hidden"
            animate={isMobileMenu ? "show" : "hidden"}
            exit={{ opacity: 0 }}
            variants={container}
            isContact={isContactOpen}
          >
            <div className="subwrapper">
              <Link
                className="btn btn--quote"
                to="/quote"
                onClick={closeOverlayMenu}
              >
                Quote
                {isNotEmpty && (
                  <span className="num-count" role="presentation">
                    <span>{orders.length}</span>
                  </span>
                )}
              </Link>

              <ul className="link-list">
                <motion.li variants={item} className="link-list__each">
                  <Link
                    to="/"
                    onClick={closeOverlayMenu}
                    activeClassName="active"
                  >
                    Home
                  </Link>
                </motion.li>
                <motion.li
                  variants={item}
                  className="link-list__each link-list__each--products"
                >
                  <Link
                    to="/products/"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsProductDropdown(!isProductDropdown);
                      setIsContact(false);
                    }}
                    activeClassName="active"
                  >
                    Products
                  </Link>{" "}
                  {isProductDropdown ? (
                    <span className="toggle-icon">&minus;</span>
                  ) : (
                    <span className="toggle-icon">+</span>
                  )}
                  {isProductDropdown && (
                    <div className="dropdown">
                      {kinds.edges.map((item) => (
                        <span className="dropdown__each" key={item.node.id}>
                          <Link
                            to={`/products?q=${toSlug(
                              item.node.data.title.text
                            )}`}
                            onClick={closeOverlayMenu}
                          >
                            {item.node.data.title.text}
                          </Link>
                        </span>
                      ))}
                      <span className="dropdown__each">
                        <Link to={`/products/`} onClick={closeOverlayMenu}>
                          View All Products
                        </Link>
                      </span>
                    </div>
                  )}
                </motion.li>
                <motion.li variants={item} className="link-list__each">
                  <Link
                    to="/about/"
                    activeClassName="active"
                    onClick={closeOverlayMenu}
                  >
                    Why choose us
                  </Link>
                </motion.li>
                <motion.li variants={item} className="link-list__each">
                  <Link
                    to="/faqs/"
                    activeClassName="active"
                    onClick={closeOverlayMenu}
                  >
                    FAQs
                  </Link>
                </motion.li>
              </ul>

              <div className="contact">
                <h6>GET IN TOUCH</h6>

                <Obfuscate
                  className="link link--email"
                  email={"sales@f-pak.com.au"}
                />
                <br />
                <a className="link" href={`tel:0732172121`}>
                  (07) 3217 2121
                </a>
              </div>

              {!isProductDropdown && (
                <button
                  className="cta"
                  onClick={() => {
                    setIsContact(true);
                  }}
                >
                  Can’t find what you’re looking for?
                </button>
              )}
            </div>
          </Wrapper>
        </ScrollLocky>
      )}
    </AnimatePresence>
  );
};

export default OverlayMobile;

const dataQuery = graphql`
  {
    kinds: allPrismicKind {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
