import addToMailchimp from "gatsby-plugin-mailchimp";
import { navigate } from "gatsby";

const mailchimpHandler = async ({ first_name, last_name, email }) => {
  // if email is valid simply navigate to success page
  try {
    const result = await addToMailchimp(email, {
      FNAME: first_name,
      LNAME: last_name,
    });
    window.sessionStorage.setItem("newsletter", true);
    if (result) {
      navigate("/submitted/", { state: { form: "newsletter" } });
    }
  } catch (e) {
    alert("Uh uh... Something went wrong, Please try again later.");
  }
};

export default mailchimpHandler;
