import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { mainBlue, mainRed, mainWhite, screen } from "../variables";
import { StaticImage } from "gatsby-plugin-image";
import { MdClose } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import mailchimpHandler from "../../helpers/mailchimp-handler";

// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1, pointerEvents: "all" },
  close: { opacity: 0, pointerEvents: "none" },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 80,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)`
  background: ${mainWhite};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 51;

  .inner {
    background: #e8e8ea;
    color: ${mainBlue};
    max-width: 1000px;
    width: calc(100% - 32px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 32px 50px 32px;
    @media ${screen.xsmall} {
      width: calc(100% - 60px);
      padding: 60px 110px 60px 110px;
    }
    @media ${screen.medium} {
      padding: 60px 130px;
    }

    .heading {
      font-size: 1.6rem;
      font-weight: 300;
      @media ${screen.xsmall} {
        font-size: 2rem;
      }
    }

    .form {
      margin: 30px 0 0 0;

      .row {
        display: flex;
        margin: 0 -8px;

        .col {
          width: 50%;
          margin: 0 8px;
        }
      }

      label {
        display: block;
        font-size: 0.85rem;
        font-weight: 500;
        margin: 0 0 7px 0;
      }

      input {
        display: block;
        width: 100%;
        border: 0;
        border-radius: 0;
        font-size: 16px;
        padding: 10px 7px;
        margin: 0 0 20px 0;
        outline: none;
        @media ${screen.xsmall} {
          margin: 0 0 25px 0;
        }
      }

      .btn {
        border: 2px ${mainBlue} solid;
        margin: 0 auto;
        @media ${screen.withCursor} {
          &:hover {
            background: ${mainBlue};
            color: ${mainWhite};
          }
        }
      }
    }

    .x-btn {
      all: unset;
      line-height: 30px;
      font-size: 1.6rem;
      position: absolute;
      right: 11px;
      top: 11px;
      cursor: pointer;
      @media ${screen.xsmall} {
        font-size: 1.95rem;
        right: 13px;
        top: 13px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainRed};
        }
      }
    }

    .graphic {
      display: none;
      @media ${screen.medium} {
        display: block;
        position: absolute;
        width: 500px;
        left: -165px;
        bottom: -160px;
        pointer-events: none;
      }
    }

    &::after {
      content: "";
      width: 100px;
      height: 100px;
      background: ${mainWhite};
      display: block;
      position: absolute;
      right: -50px;
      bottom: -50px;
      transform: rotate(45deg);
      @media ${screen.xsmall} {
        width: 150px;
        height: 150px;
        right: -75px;
        bottom: -75px;
      }
    }
  }
`;

const Newsletter = ({ isActive }) => {
  const [userInput, setUserInput] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [isNewsletterPopup, setIsNewsletterPopup] = useState(false);

  const handleChange = (e) => {
    userInput[e.target.name] = e.target.value;

    setUserInput({ ...userInput });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);
    mailchimpHandler({
      first_name: userInput.first_name,
      last_name: userInput.last_name,
      email: userInput.email,
    });
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("newsletter")) {
      setIsNewsletterPopup(false);
    } else {
      setTimeout(() => {
        setIsNewsletterPopup(true);
      }, 5000);
    }

    const userClick = (e) => {
      if (e.target.id === "newsletter") {
        closePopup();
      }
    };
    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  const closePopup = () => {
    window.sessionStorage.setItem("newsletter", true);
    setIsNewsletterPopup(false);
  };

  return (
    <AnimatePresence>
      {isNewsletterPopup && (
        <Wrapper
          initial="close"
          animate={isNewsletterPopup ? "open" : "close"}
          variants={variantsContainer}
          exit={{ opacity: 0 }}
          id="newsletter"
        >
          <motion.div className="inner" variants={variantsList}>
            <motion.div variants={variantsItem}>
              <h6 className="heading">
                Stay in the loop for new products & promotions.
              </h6>

              <form className="form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col">
                    <label>FIRST NAME</label>
                    <input
                      type="text"
                      name="first_name"
                      onChange={handleChange}
                      value={userInput.first_name || ""}
                      required
                    />
                  </div>
                  <div className="col">
                    <label>LAST NAME</label>
                    <input
                      type="text"
                      name="last_name"
                      onChange={handleChange}
                      value={userInput.last_name || ""}
                      required
                    />
                  </div>
                </div>
                <label>EMAIL ADDRESS</label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={userInput.email || ""}
                  required
                />

                <motion.button className="btn" whileTap={{ scale: 0.96 }}>
                  {isSending ? "PLEASE WAIT..." : "STAY UPDATED"}
                </motion.button>
              </form>
            </motion.div>
            <button className="x-btn" onClick={closePopup}>
              <MdClose />
            </button>

            <StaticImage
              className="graphic"
              src="../../images/F-Pak-Stay-In-The-Loop.png"
              alt="Box"
            />
          </motion.div>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default Newsletter;
